// エディタ内部のスタイル

.bs-ed-root {

	// @font-family: "Segoe UI", "ヒラギノ角ゴ ProN", Meiryo, sans-serif;
	@text-color: #333;
	@link-color: #05b;

	font-family: @font-family;
	font-weight: 400;
	line-height: 1.5;

	a {
		color: @link-color;
		text-decoration: underline;
		cursor: text;
	}

	.heading,
	.subhead,
	.subsubhead,
	p {
		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}

	.heading {
		margin: 1.5em/1.25 0 1em/1.25;
		padding: .4em .5em;
		font-size: 125%; font-weight: 700;
		color: #fff; background: #428bca;
		border-radius: 6px;
	}

	.subhead {
		margin: 1.5em/1.08 0 1em/1.08;
		padding: .4em .5em;
		font-size: 108%; font-weight: 700;
		color: @text-color; background: #ebf1f9;
		border: none;
		border-bottom: solid 1px #e6e6e6;
		border-left: solid 5px #75ade6;
	}

	.subsubhead {
		margin: 1.5em 0 1em;
		padding: .25em 0;
		font-size: 100%; font-weight: 700;
		color: @text-color; background: transparent;
		border: none;
		border-bottom: solid 1px #ddd;
	}

	p {
		margin: 1em 0;
		padding: 0;
		font-size: 100%; font-weight: 400;
		border: none;
	}

	ul,
	ol {
		margin: 1em 0 1em 2em;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}
}
