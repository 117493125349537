// ================================================================
// 地図

.bs-ed-preview-Map {
	width: 100%; height: 220px;
	margin: 15px 0;
	> .bs-replace-map { height: 100%; }
}

.bs-ed-view-Map {
	position: relative;
	width: 100%; height: 220px;
	
	> .map { width: 100%; height: 100%; border: solid 1px #ddd; background: #f8f8f8; }
	
	> .map-tools {
		position: absolute; right: 9px; top: 9px;
		width: auto;
		
		.bs-ed-btn {
			font-size: 13px;
			line-height: 1;
			padding: 9px 9px 7px;
		}
	}
}
