// ================================================================
// 見出し

.bs-ed-preview-Heading {
	position: relative;
	margin: 20px 0 5px;
	font-size: 120%; line-height: 1.2; font-weight: bold;
}

.bs-ed-view-Heading
:extend(.bs-ed-preview-Heading all) {
	margin: 0;
	padding-bottom: 0;
	
	textarea {
		width: 100%; height: 1.2em; line-height: 1.2;
		margin: 0; padding: 5px 0;
		border: none; resize: none; overflow: hidden;
		border-bottom: solid 2px #999;
		&:focus { outline: none; }
	}
}

// ================================================================
// 副見出し

.bs-ed-preview-Subheading {
	position: relative;
	margin: 20px 0 10px;
	font-size: 112%; line-height: 1.2; font-weight: bold;
	color: #666;
}

.bs-ed-view-Subheading
:extend(.bs-ed-preview-Subheading all) {
	margin: 0;
	padding-bottom: 0;
	
	textarea {
		width: 100%; height: 1.2em; line-height: 1.2;
		margin: 0; padding: 5px 0;
		border: none; resize: none; overflow: hidden;
		border-bottom: dashed 1px #ddd;
		color: #666;
		&:focus { outline: none; }
	}
}

