.bs-ed-preview-plugin {
	margin: 15px auto;
	text-align: center;

	iframe {
		max-width: 100%;
	}
}

.bs-ed-view-GMap {
	// valid URL
	&.llv {
		.lli .bs-ed-icon {
			color: #1da261;
			opacity: 1;
		}
	}

	.lli {
		display: flex;
		align-items: center;
		width: 100%;
		border: solid 1px #ddd;
		background: #fff;

		.bs-ed-icon {
			padding: 3px;
			padding-right: 0;
			font-size: 18px;
			opacity: .8;
		}

		.bs-ed-input-tr {
			flex: 1;
			padding: 5px 8px;
		}
	}

	.llp {
		position: relative;
		text-align: center;
		min-height: 80px;
		max-height: 250px;
		overflow: auto;
		border: dashed 2px #e4e4e4;

		> iframe {
			vertical-align: top;
			max-width: 100%;
		}

		// プレビューの操作を防ぐ
		&:after {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			.pos(0);
		}
	}
	.llph {
		display: none;
	}

	.llh {
		margin-top: 3px;
		font-weight: 700;
		color: @error-fg;
	}
}
