// ================================================================
// 本文+図

.bs-ed-preview-FloatPicture {
	.clearfix;
	margin: 15px -10px;
	
	& + & { margin-top: 30px; }
	
	.txt {
		margin: 0; padding: 0 10px;
		width: 66.66%;
		
		&.right { float: left; }
		&.left { float: right; }
	}
	
	.fig {
		width: 33.33%;
		padding: 0 10px;
		text-align: center;
		
		&.right { float: right; }
		&.left { float: left; }
	}
}

.bs-ed-view-FloatPicture {
	position: relative;
	min-height: 148px;

	.ctxt {
		width: 100%;

		> .bs-ed-view-Richtext-root {
			min-height: 144px;
		}
	}
	
	.cpic {
		position: absolute; top: 0;
		width: 220px;
	}
	
	.ctgl {
		display: block; width: 29px;
		position: absolute; top: 0; bottom: 0;
		padding: 0;
		border-radius: 0; outline: none;
		color: #777;
		&:hover { color: #333; }
		
		font-size: 0; // hide text
		
		&:before {
			.apply-bs-ed-icon();
			content: @bs-ed-icon-toggle-h;
			font-size: 13px;
		}
	}
	
	&.left {
		padding-left: 240px;
		
		.cpic { left: 0; padding-right: 35px; }
		.ctgl { right: 0; }
	}
	&.right {
		padding-right: 240px;
		
		.cpic { right: 0; padding-left: 35px; }
		.ctgl { left: 0; }
	}
}

