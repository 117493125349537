// ================================================================
// Twitter

.bs-ed-preview-Twitter {
	max-width: 600px;
}

.bs-ed-view-Twitter {
	// valid URL
	&.llv {
		.lli .bs-ed-icon {
			color: #1ea1f2;
			opacity: 1;
		}
	}

	.lli {
		display: flex;
		align-items: center;
		width: 100%;
		border: solid 1px #ddd;
		background: #fff;

		.bs-ed-icon {
			padding: 3px;
			padding-right: 0;
			font-size: 18px;
			opacity: .8;
		}

		.bs-ed-input-tr {
			flex: 1;
			padding: 5px 8px;
		}
	}

	.llp {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 200px;
		color: #777;
		background: #f4f4f4;
		cursor: default;
		user-select: none;

		.bs-ed-icon {
			margin: -4px 0 0;
			width: 80px;
			height: 80px;
			font-size: 80px;
			line-height: 1;
			color: #ccc;
		}
	}
	.llph {
		display: none;
	}

	.llh {
		margin-top: 3px;
		font-weight: 700;
		color: @error-fg;
	}
}
