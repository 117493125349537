// ================================================================
// 表

.bs-ed-preview-Tabular {
	border-collapse: collapse;
	margin: 15px 0;
	//width: 100%;
	
	th,
	td {
		vertical-align: middle;
		padding: 7px 10px;
		border: solid 1px #ccc;
		background: #fff;
	}
	
	th {
		font-weight: normal;
		background: @bs-ed-table-th-bg;
	}

	th,
	td {
		p,
		ul,
		ol {
			margin-top: 0;
			margin-bottom: 0;
		}
		ul,
		ol {
			margin-left: 1.5em;
		}
	}
}

.bs-ed-view-Tabular {
	// <tbody> 相当
	.bs-ed-tbrows {
		padding-bottom: 1px;
	}

	// <tr> 相当
	.bs-ed-tbrow {
		display: table;
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		margin-bottom: -1px;
		position: relative;
	}

	// <td>/<th> 相当
	.bs-ed-tbcol {
		display: table-cell;
		vertical-align: top;
		width: 100%;
		border: solid 1px #bbb;
		background: #fff;
	}

	// <th> 相当
	.bs-ed-tbth {
		background: @bs-ed-table-th-bg;
	}

	// 行のツール
	.bs-ed-tbrtool {
		vertical-align: top;
		width: 0;
	}
	.bs-ed-tbrtooli {
		display: none;
		position: absolute;
		.pos(0);
		padding: 3px 0 0 2px;

		.bs-ed-danger {
			position: absolute;
			left: 100%;
			margin-left: 4px;
		}
	}

	// 行ごとの編集時の動作
	.bs-ed-tbredit {
		padding-right: 14px;

		.bs-ed-tbrtooli {
			display: block;
			cursor: move;
		}
	}

	.bs-ed-tbghost {
		opacity: .5;
		background: #c8ebfb;
	}

	// 表のツール
	.bs-ed-tbtool {
		margin: .375em 0 0;
		opacity: .6;

		.bs-ed-btn {
			margin-right: .375em;
		}
	}
	&:hover .bs-ed-tbtool {
		opacity: 1;
	}

	// セル中の Richedit の設定
	.bs-ed-tbcol .bs-ed-root {
		padding: .375em .5em;
		min-height: 2.25em; // 0.375em + 1.5em + 0.375em

		p,
		ul,
		ol {
			margin-top: 0;
			margin-bottom: 0;
		}
		ul,
		ol {
			margin-left: 1.5em;
		}
	}
}
