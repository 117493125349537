.bs-ed-mobile-edit,
.bs-ed-mobile-tool {
	display: none;
}

.bs-ed-mobile-tool {
	position: relative;
	flex: 0 0 50px;
}
.bs-ed-mobile-done {
	position: absolute;
	.pos(0, 0, 0, auto);
	width: 50px;

	padding: 0 10px;
	font-size: 15px;

	color: #fff; background: #5cb85c;
	border: none; border-radius: 4px;
}

@media @bs-ed-mobile {

	.bs-ed-mobile-edit {
		display: block;
		position: absolute;
		.pos(0); z-index: 50;
		background: fade(#eee, 25%);
		cursor: pointer;

		.bs-ed-mobile-edit-enable & {
			display: none;
		}
	}

	.bs-ed-toolbar-wrap {
		display: none;
	}

	html.bs-ed-mobile-edit-enable {
		&,
		& > body {
			overflow: hidden;
			height: 100%;
		}
	}

	.bs-ed-view-Editor.bs-ed-mobile-edit-enable {
		position: fixed;
		.pos(0); z-index: 1100;
		height: auto;
		border-radius: 0;

		display: flex;
		flex-direction: column;

		> .bs-ed-toolbar-wrap {
			display: flex;
			flex: 0 0 auto;
		}

		.bs-ed-mobile-tool {
			display: block;
		}

		> .bs-ed-content-scroll {
			display: block;
			flex: 1 0 auto;
			overflow-y: auto;
		}
		.bs-ed-content-wrap {
			height: auto;
		}
	}

	// ブロックの削除・並べ替え部分の空間
	.bs-ed-content-wrap:after {
		content: none;
		display: none;
	}

	// ブロック
	.bs-ed-edititem {
		margin-right: 0;
		padding-right: 7px;
	}

	// ブロックの削除・並べ替え部分の内容
	.bs-ed-edititem-tool {
		opacity: 1;
		top: @bs-ed-inserter-height / 2 + @bs-ed-inserter-margin - 15.5px;

		.bs-ed-editor-sort-handle {
			display: none;
		}
	}

	.bs-ed-inserter-active .bs-ed-edititem-tool {
		opacity: 0 !important;
	}

	// 挿入ボタンを常に表示する
	.bs-ed-inserter-cnt {
		visibility: visible !important;
	}

	.bs-ed-inserter-btns {
		display: inline-flex;
		justify-content: center;
		max-width: 100%;

		> .bs-ed-inserter-btn {
			flex: 0 1 auto;
			width: 32px;
			padding-left: 0 !important; padding-right: 0 !important;
			text-align: center;
		}
	}

	// ----------------
	// FloatPicture

	.bs-ed-view-FloatPicture {
		&.left {
			padding-left: 160px;
		}
		&.right {
			padding-right: 160px;
		}
		.cpic {
			width: 150px;
		}
	}

	// ----------------
	// Picture

	.bs-ed-view-PictureItem {
		.cimgrm.cimgrm { display: block; }
	}

	// ----------------
	// Table

	.bs-ed-view-TableRow {
		.row-tool {
			width: 28px;
			opacity: 1;
		}
		.header & .row-tool {
			opacity: 0;
		}
		.bs-ed-editor-sort-handle {
			display: none;
		}
	}

	// ----------------
	// FileList

	.bs-ed-view-File {
		.thumb {
			width: 72px;
		}
		.tool {
			width: 28px;
			opacity: 1;
		}
		.bs-ed-editor-sort-handle {
			display: none;
		}
	}
}
