// ================================================================
// 書式なしテキスト

.bs-ed-preview-Plaintext {
	margin: 5px 0;
}

.bs-ed-view-Plaintext {
	textarea {
		width: 100%; height: 1.5em; min-height: 3em;
		margin: 0; padding: 0;
		font-size: 15px; line-height: 1.5;
		vertical-align: top;
		border: none; resize: none; overflow: hidden;
		&:focus { outline: none; }
		
		.no-textarea-adjust & { height: 7.5em; overflow-y: scroll; }
	}
}

