// Buttons
// ----------------------------------------------------------------

.bs-ed-btn-base() {
	display: inline-block;
	line-height: 1.25;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	border-radius: .3em;

	&:disabled,
	&.disabled {
		cursor: default;
		opacity: 0.6;
	}

	&:focus,
	&.focus {
		outline: 0;
	}
}

.bs-ed-btn {
	.bs-ed-btn-base();
	padding: .375em 1em;
	border: solid 1px;
}

// 透明フォーム
.bs-ed-btn-tr {
	.bs-ed-btn-base();
	background: transparent;
	border: none;

	color: @bs-ed-ui-muted-fg;

	&:hover,
	&:focus {
		color: darken(@bs-ed-ui-muted-fg, 10%);
	}
}

.bs-ed-btn-color(@fg, @bg, @border) {
	color: @fg;

	&,
	&:disabled,
	&.disabled {
		background-color: @bg;
		border-color: @border;
	}

	&:disabled,
	&.disabled {
		color: mix(#ddd, @fg, 80%);
	}

	&:hover,
	&:focus {
		color: @fg;
		background-color: darken(@bg, 5%);
		border-color: darken(@border, 5%);
	}

	&:active,
	&.active {
		color: darken(@fg, 15%);
		background-color: darken(@bg, 12%);
		border-color: darken(@border, 12%);
	}
}
.bs-ed-btn-color(@fg, @bg) {
	.bs-ed-btn-color(@fg, @bg, @bg);
}

// Color
// ----------------------------------------------------------------

.bs-ed-btn-primary {
	.bs-ed-btn-color(#fff, @theme-color);
}

.bs-ed-btn-default {
	.bs-ed-btn-color(@bs-ed-ui-fg, #fff, @bs-ed-ui-border);
}

// Size
// ----------------------------------------------------------------

.bs-ed-wrap {
	.bs-ed-btn-xl {
		font-size: 130.6%;
	}

	.bs-ed-btn-lg {
		font-size: 114.4%;
	}

	.bs-ed-btn-sm {
		font-size: 87.5%;
	}

	.bs-ed-btn-xs {
		font-size: 76.6%;
	}
}
