// ================================================================
// リンク

.bs-ed-preview-LinkList {
	margin: 10px 0; padding: 8px 10px;
	background: #fdf2f4;

	.hd {
		display: inline-block;
		margin: 0 0 5px;
		padding: 3px 5px;
		line-height: 1.2;
		color: #fff; background: #d82d47;
		border-radius: 2px;
	}
	
	ul {
		margin: 0;
		list-style: disc;
	}
}

.bs-ed-view-LinkList {
	padding: 5px;
	background: #fdf2f4;
	border-radius: 3px;
	
	.hd {
		display: inline-block;
		margin: 0 0 3px;
		padding: 2px 5px;
		font-size: 87.5%; font-weight: bold; line-height: 1.2;
		color: #fff; background: #d82d47;
		border-radius: 3px;
	}
	
	.bs-ed-view-Link {
		margin-bottom: 3px;
	}
}

.bs-ed-view-Link {
	position: relative;
	display: table; width: 100%;
	background: #f6f6f7 linear-gradient(to bottom, #f6f6f7, darken(#f6f6f7, 2%));
	border: solid 1px #d8d8d6;
	
	.icon {
		position: relative;
		display: table-cell; vertical-align: middle;
		width: 52px;
		
		&:before {
			.apply-bs-ed-icon();
			content: @bs-ed-icon-link;
			display: block;
			position: absolute; left: 50%; top: 50%;
			margin: -12px;
			font-size: 24px; color: #bbb;
		}
	}
	
	.input {
		display: table-cell; vertical-align: middle;
		padding: 5px 2px 5px 0;
	}
	
	.form-control {
		background: #fff;
	}
	
	.input-url {
		height: 26px;
		margin-top: 2px;
		padding: 4px 10px;
		font-size: 11px; line-height: 1.4;
		border-radius: 3px;
	}
	
	.tool {
		display: table-cell; vertical-align: middle;
		width: 53px;
		padding: 3px 3px 0 0;
		opacity: 0;
	}
	&:hover .tool { opacity: 1; }

	// 列が1つしかない場合は「削除」「ソート」は不要
	&:first-child:last-child .tool > * { display: none; }
}

