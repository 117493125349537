@keyframes bs-ed-backdrop-in {
	0% { opacity: 0; }
	100% { opacity: .25; }
}

@keyframes bs-ed-backdrop-out {
	0% { opacity: .25; }
	100% { opacity: 0; }
}

.bs-ed-backdrop {
	display: none;
	position: fixed;
	z-index: 1200;
	.pos(0);
	background-color: #444;
	transition: opacity .15s linear;

	&,
	& * {
		box-sizing: border-box;
	}

	&.active {
		display: block;
		opacity: .25;
		animation: bs-ed-backdrop-in linear .2s;
	}

	&.active.out {
		opacity: 0;
		animation: bs-ed-backdrop-out linear .2s;
	}
}
