.bs-ed-wrap {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.bs-ed-wrap *,
.bs-ed-wrap *::before,
.bs-ed-wrap *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bs-ed-wrap input,
.bs-ed-wrap button,
.bs-ed-wrap textarea,
.bs-ed-wrap select {
  font-size: inherit;
  font-family: inherit;
}

.bs-ed-wrap [tabindex="-1"]:focus,
.bs-ed-wrap [tabindex="-1"]:active {
  outline: 0 !important;
}

.bs-ed-act {
  cursor: pointer;
}

.bs-ed-view-Editor {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background: #f7f7f6;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bs-ed-content-scroll,
.bs-ed-content-wrap {
  height: 350px;
}

.bs-ed-content-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.bs-ed-content-wrap {
  display: table;
  width: 100%;
}

.bs-ed-content-wrap:after {
  content: "";
  display: table-cell;
  width: 65px;
}

.bs-ed-content {
  position: relative;
  display: table-cell;
  padding: 4px;
  background: #fff;
  border-right: solid 1px #d8d8d6;
  border-radius: 4px 0 0 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bs-ed-edititem-tool {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 3px;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.bs-ed-edititem {
  position: relative;
  margin: 0;
  margin-right: -66px;
  padding: 0 75px 0 7px;
  border-radius: 3px;
}

.bs-ed-edititem:hover,
.bs-ed-edititem.ui-sortable-helper {
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
}

.bs-ed-edititem:hover .bs-ed-edititem-tool,
.bs-ed-edititem.ui-sortable-helper .bs-ed-edititem-tool {
  opacity: 1;
}

.bs-ed-edititem.ui-sortable-helper {
  opacity: 0.8;
}

.bs-ed-edititem + .bs-ed-edititem {
  margin-top: -40px;
}

.bs-ed-edititem .bs-ed-inserter-cnt {
  visibility: hidden;
}

.bs-ed-edititem.hover .bs-ed-inserter-cnt,
.bs-ed-edititem.focus .bs-ed-inserter-cnt {
  visibility: visible;
}

.bs-ed-inserter-cnt {
  position: relative;
  height: 40px;
  margin: 0;
  padding: 2px 0;
  overflow: hidden;
  text-align: center;
}

.bs-ed-inserter-cnt:before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  border-bottom: solid 1px #ddd;
}

.bs-ed-inserter-open-btn {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 36px;
  padding: 0;
  font: 200 14px/26px Arial, sans-serif;
  text-align: center;
  color: #bbb;
  background: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.bs-ed-inserter-open-btn:hover,
.bs-ed-inserter-open-btn:focus {
  color: #888;
  border-color: #aaa;
  outline: none;
}

.bs-ed-inserter-open-btn:active {
  color: #888;
  border-color: #aaa;
  background: #eee;
}

.bs-ed-inserter-open-btn .bs-ed-icon {
  display: inline-block;
  height: 26px;
  padding: 6px 0;
}

.bs-ed-inserter {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 2px;
  left: 0;
  z-index: 2;
  text-align: center;
}

.bs-ed-inserter-btns {
  display: inline-block;
  height: 36px;
  color: #888;
  background: #fff;
  border: solid 1px #aaa;
  border-radius: 6px;
  overflow: hidden;
}

.bs-ed-inserter-btns > .bs-ed-inserter-btn:first-child {
  padding-left: 10px;
}

.bs-ed-inserter-btns > .bs-ed-inserter-btn:last-child {
  padding-right: 10px;
}

.bs-ed-inserter-btn {
  height: 34px;
  margin: 0;
  padding: 9px 10px;
  line-height: 16px;
  text-align: center;
  color: #888;
  background: #fff;
  border: none;
}

.bs-ed-inserter-btn + .bs-ed-inserter-btn {
  border-left: solid 1px #ddd;
}

.bs-ed-inserter-btn:hover,
.bs-ed-inserter-btn:focus {
  color: #666;
}

.bs-ed-inserter-btn:active {
  background: #eee;
}

.bs-ed-inserter-btn .bs-ed-icon {
  line-height: 16px;
}

.bs-ed-inserter-btn-as {
  display: none;
}

.bs-ed-inserter-btn-back {
  border-left: none !important;
}

.bs-ed-inserter-btn-alt .bs-ed-inserter-btn {
  display: none;
}

.bs-ed-inserter-btn-alt .bs-ed-inserter-btn-as {
  display: inline-block;
}

.bs-ed-inserter-btn-ic {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.bs-ed-inserter-btn-tx {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  font-size: 75%;
}

@font-face {
  font-family: "bs-ed-icon";
  src: url("../fonts/bs-ed-icon.woff?v=1613982441007") format("woff"), url("../fonts/bs-ed-icon.woff2?v=1613982441007") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.bs-ed-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.bs-ed-icon:before {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  font-family: "bs-ed-icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bs-ed-icon-lg {
  font-size: 1.333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.bs-ed-icon-2x {
  font-size: 200%;
}

.bs-ed-icon-3x {
  font-size: 300%;
}

.bs-ed-icon-4x {
  font-size: 400%;
}

.bs-ed-icon-5x {
  font-size: 500%;
}

.bs-ed-icon-big:before {
  content: "\F001";
}

.bs-ed-icon-bold:before {
  content: "\F002";
}

.bs-ed-icon-button:before {
  content: "\F003";
}

.bs-ed-icon-code:before {
  content: "\F004";
}

.bs-ed-icon-cog:before {
  content: "\F005";
}

.bs-ed-icon-color:before {
  content: "\F006";
}

.bs-ed-icon-copy:before {
  content: "\F007";
}

.bs-ed-icon-dots:before {
  content: "\F008";
}

.bs-ed-icon-edit:before {
  content: "\F009";
}

.bs-ed-icon-facebook:before {
  content: "\F00A";
}

.bs-ed-icon-file:before {
  content: "\F00B";
}

.bs-ed-icon-float:before {
  content: "\F00C";
}

.bs-ed-icon-hash:before {
  content: "\F00D";
}

.bs-ed-icon-heading1:before {
  content: "\F00E";
}

.bs-ed-icon-heading2:before {
  content: "\F00F";
}

.bs-ed-icon-heading3:before {
  content: "\F010";
}

.bs-ed-icon-link:before {
  content: "\F011";
}

.bs-ed-icon-list-bullet:before {
  content: "\F012";
}

.bs-ed-icon-list-ordered:before {
  content: "\F013";
}

.bs-ed-icon-map:before {
  content: "\F014";
}

.bs-ed-icon-picture:before {
  content: "\F015";
}

.bs-ed-icon-plus:before {
  content: "\F016";
}

.bs-ed-icon-remove:before {
  content: "\F017";
}

.bs-ed-icon-search:before {
  content: "\F018";
}

.bs-ed-icon-small:before {
  content: "\F019";
}

.bs-ed-icon-strike:before {
  content: "\F01A";
}

.bs-ed-icon-table:before {
  content: "\F01B";
}

.bs-ed-icon-text:before {
  content: "\F01C";
}

.bs-ed-icon-toggle-h:before {
  content: "\F01D";
}

.bs-ed-icon-toggle-v:before {
  content: "\F01E";
}

.bs-ed-icon-twitter:before {
  content: "\F01F";
}

.bs-ed-icon-underline:before {
  content: "\F020";
}

.bs-ed-icon-video:before {
  content: "\F021";
}

@keyframes bs-ed-backdrop-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes bs-ed-backdrop-out {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
  }
}

.bs-ed-backdrop {
  display: none;
  position: fixed;
  z-index: 1200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #444;
  transition: opacity 0.15s linear;
}

.bs-ed-backdrop,
.bs-ed-backdrop * {
  box-sizing: border-box;
}

.bs-ed-backdrop.active {
  display: block;
  opacity: 0.25;
  animation: bs-ed-backdrop-in linear 0.2s;
}

.bs-ed-backdrop.active.out {
  opacity: 0;
  animation: bs-ed-backdrop-out linear 0.2s;
}

.bs-ed-btn {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.3em;
  padding: 0.375em 1em;
  border: solid 1px;
}

.bs-ed-btn:disabled,
.bs-ed-btn.disabled {
  cursor: default;
  opacity: 0.6;
}

.bs-ed-btn:focus,
.bs-ed-btn.focus {
  outline: 0;
}

.bs-ed-btn-tr {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.3em;
  background: transparent;
  border: none;
  color: #777;
}

.bs-ed-btn-tr:disabled,
.bs-ed-btn-tr.disabled {
  cursor: default;
  opacity: 0.6;
}

.bs-ed-btn-tr:focus,
.bs-ed-btn-tr.focus {
  outline: 0;
}

.bs-ed-btn-tr:hover,
.bs-ed-btn-tr:focus {
  color: #5e5e5e;
}

.bs-ed-btn-primary {
  color: #fff;
}

.bs-ed-btn-primary,
.bs-ed-btn-primary:disabled,
.bs-ed-btn-primary.disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.bs-ed-btn-primary:disabled,
.bs-ed-btn-primary.disabled {
  color: #e4e4e4;
}

.bs-ed-btn-primary:hover,
.bs-ed-btn-primary:focus {
  color: #fff;
  background-color: #0d95e8;
  border-color: #0d95e8;
}

.bs-ed-btn-primary:active,
.bs-ed-btn-primary.active {
  color: #d9d9d9;
  background-color: #0b7fc6;
  border-color: #0b7fc6;
}

.bs-ed-btn-default {
  color: #444;
}

.bs-ed-btn-default,
.bs-ed-btn-default:disabled,
.bs-ed-btn-default.disabled {
  background-color: #fff;
  border-color: #ccc;
}

.bs-ed-btn-default:disabled,
.bs-ed-btn-default.disabled {
  color: #bebebe;
}

.bs-ed-btn-default:hover,
.bs-ed-btn-default:focus {
  color: #444;
  background-color: #f2f2f2;
  border-color: #bfbfbf;
}

.bs-ed-btn-default:active,
.bs-ed-btn-default.active {
  color: #1e1e1e;
  background-color: #e0e0e0;
  border-color: #adadad;
}

.bs-ed-wrap .bs-ed-btn-xl {
  font-size: 130.6%;
}

.bs-ed-wrap .bs-ed-btn-lg {
  font-size: 114.4%;
}

.bs-ed-wrap .bs-ed-btn-sm {
  font-size: 87.5%;
}

.bs-ed-wrap .bs-ed-btn-xs {
  font-size: 76.6%;
}

.bs-ed-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bs-ed-input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.bs-ed-input:disabled {
  color: #666;
  background-color: #eee;
  cursor: default;
}

.bs-ed-input-lt {
  padding: 0.375em 0.25em;
  line-height: 1.42857143;
  color: #333;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
}

.bs-ed-input-lt:focus {
  background: rgba(255, 255, 255, 0.8);
}

.bs-ed-input-lt:focus,
.bs-ed-input-lt:hover {
  border-color: #bbb;
}

.bs-ed-input-tr {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
}

.bs-ed-input::placeholder,
.bs-ed-input-tr::placeholder {
  color: #aaa;
  opacity: 1;
}

.bs-ed-input:-ms-input-placeholder,
.bs-ed-input-tr:-ms-input-placeholder {
  color: #aaa;
  opacity: 1;
}

.bs-ed-form-label {
  padding: 0 0 0.375em;
  font-weight: 700;
}

.bs-ed-checkbox {
  display: inline-block;
  padding: 0.375em 0;
}

.bs-ed-input-desc {
  margin: 0.375em 0 0;
  font-size: 87.5%;
  color: #777;
}

.bs-ed-input-desc:empty {
  margin: 0;
}

.bs-ed-input-group {
  display: flex;
}

.bs-ed-input-group > .bs-ed-input {
  flex: 1 1 auto;
  min-width: 0;
}

.bs-ed-input-group > * {
  border-radius: 0;
}

.bs-ed-input-group > *:first-child {
  border-radius: 0.25em 0 0 0.25em;
}

.bs-ed-input-group > *:last-child {
  border-radius: 0 0.25em 0.25em 0;
}

.bs-ed-input-group > * ~ * {
  border-left: none;
}

.bs-ed-form-group + .bs-ed-form-group {
  margin-top: 0.5em;
}

.bs-ed-form-group-inline {
  display: flex;
}

.bs-ed-form-group-inline .bs-ed-form-label {
  flex: 0 0 auto;
  width: 20%;
  min-width: 5.5em;
  min-height: 1px;
  margin-bottom: 0;
  padding: 0.375em 0 0;
  text-align: right;
}

.bs-ed-form-group-inline .bs-ed-form-controls {
  flex: 0 1 auto;
  width: 80%;
  min-height: 1px;
  padding-left: 1em;
}

@keyframes bs-ed-dialog-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bs-ed-dialog-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bs-ed-dialog {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1210;
  margin: 0;
  padding: 0 1em;
  outline: 0;
  line-height: 1.4;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.bs-ed-dialog,
.bs-ed-dialog * {
  box-sizing: border-box;
}

.bs-ed-dialog.active {
  opacity: 1;
  animation: bs-ed-dialog-in linear 0.2s;
}

.bs-ed-dialog.active.out {
  opacity: 0;
  animation: bs-ed-dialog-out linear 0.2s;
}

.bs-ed-dialog-dialog {
  margin: 50px auto auto;
  max-width: 600px;
}

.bs-ed-dialog.fade > .bs-ed-dialog-dialog {
  transform: translate(0, -5%);
  transition: transform 0.2s ease-out;
}

.bs-ed-dialog.in > .bs-ed-dialog-dialog {
  transform: translate(0, 0);
}

.bs-ed-dialog-content {
  position: relative;
  margin: 0;
  padding: 0;
  color: #333;
  background: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(48, 48, 48, 0.2);
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.bs-ed-dialog-title,
.bs-ed-dialog-subtitle {
  margin: 10px 12px;
}

.bs-ed-dialog-title {
  font-size: 114%;
  font-weight: 700;
}

.bs-ed-dialog-subtitle {
  font-size: 100%;
}

.bs-ed-dialog-title + .bs-ed-dialog-subtitle {
  margin-top: -4px;
}

.bs-ed-dialog-actions {
  margin: 0;
  padding: 0.5em 0.75em;
  text-align: center;
  background: #eee;
  border-radius: 0 0 6px 6px;
}

.bs-ed-dialog-actions > .bs-ed-btn {
  margin: 0 0.25em;
  min-width: 7em;
}

.bs-ed-toolbar-wrap {
  display: flex;
  padding: 3px 2px;
  color: #444;
  background: #f4f4f4;
  border-bottom: solid 1px #ccc;
}

.bs-ed-toolbar {
  flex: 1 1 auto;
}

.bs-ed-toolbar-grp {
  display: inline-block;
  vertical-align: bottom;
}

.bs-ed-toolbar-grp:after {
  display: inline-block;
  vertical-align: bottom;
  content: "";
  margin: 0 2px;
  width: 1px;
  height: 32px;
  background: #ddd;
}

.bs-ed-toolbar-grp:last-child:after {
  content: none;
}

.bs-ed-toolbar-item {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 1px;
}

.bs-ed-toolbar-btn {
  display: inline-block;
  vertical-align: bottom;
  height: 32px;
  line-height: 1;
  padding: 7px 8px;
  white-space: nowrap;
  color: #444;
  background: #f4f4f4;
  border: solid 1px transparent;
  border-radius: 3px;
  transition: border-color 0.15s linear, background-color 0.15s linear;
}

.bs-ed-toolbar-btn,
.bs-ed-wrap .bs-ed-toolbar-btn {
  font-size: 16px;
}

.bs-ed-toolbar-btn + .bs-ed-toolbar-btn {
  margin-left: 0;
}

.bs-ed-toolbar-btn:hover,
.bs-ed-toolbar-btn:focus {
  background-color: #f8f8f8;
  border-color: #ccc;
}

.bs-ed-toolbar-btn:active,
.bs-ed-toolbar-btn.active {
  border-color: #ccc;
  background-color: #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: none;
}

.bs-ed-toolbar.disabled .bs-ed-toolbar-btn {
  color: #aaa;
  cursor: default;
}

.bs-ed-toolbar.disabled .bs-ed-toolbar-btn:hover,
.bs-ed-toolbar.disabled .bs-ed-toolbar-btn:focus,
.bs-ed-toolbar.disabled .bs-ed-toolbar-btn:active,
.bs-ed-toolbar.disabled .bs-ed-toolbar-btn.active {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.bs-ed-toolbar-btn .bs-ed-ic {
  font-size: 16px;
}

.bs-ed-toolbar-dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 32px;
  z-index: 50;
  background: #f4f4f4;
  border: solid 1px #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.bs-ed-toolbar-dropdown.bs-ed-active {
  display: block;
}

.bs-ed-textcolor-dropdown {
  width: 118px;
  padding: 2px;
}

.bs-ed-textcolor-dropdown > .bs-ed-toolbar-item {
  margin: 0;
}

.bs-ed-textcolor-dropdown-item {
  display: inline-block;
  vertical-align: top;
  margin: 2px;
  width: 24px;
  height: 24px;
  border: solid 1px #ccc;
  border-radius: 2px;
  cursor: pointer;
}

.bs-ed-toolbutton {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin: 3px;
  padding: 3px 0 0;
  border: none;
  width: 19px;
  height: 19px;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  border-radius: 19px;
  color: #fff;
  background: #bbb;
  cursor: pointer;
}

.bs-ed-toolbutton:focus {
  outline: none;
}

.bs-ed-toolbutton:hover {
  background: #444;
}

.bs-ed-toolbutton:active {
  color: #e6e6e6;
  background: #2b2b2b;
}

.bs-ed-toolbutton.bs-ed-danger:hover {
  background: #cd1e10;
}

.bs-ed-toolbutton.bs-ed-danger:active {
  background: #9e170c;
}

.bs-ed-mobile-edit,
.bs-ed-mobile-tool {
  display: none;
}

.bs-ed-mobile-tool {
  position: relative;
  flex: 0 0 50px;
}

.bs-ed-mobile-done {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 50px;
  padding: 0 10px;
  font-size: 15px;
  color: #fff;
  background: #5cb85c;
  border: none;
  border-radius: 4px;
}

.bs-ed-preview-PictureItem .caption {
  display: block;
  margin-top: 5px;
  text-align: center;
}

.bs-ed-preview-PictureItem img,
.bs-ed-preview-PictureItem object {
  max-width: 100%;
}

.bs-ed-view-PictureItem {
  background: #fff;
  border: solid 1px #d8d8d6;
}

.bs-ed-view-PictureItem .cimg {
  position: relative;
  height: 120px;
}

.bs-ed-view-PictureItem .cimgrm {
  display: none;
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 10;
  width: 19px;
  height: 19px;
  line-height: 13px;
  font-size: 13px;
  margin: 0;
  padding: 3px 0 0;
  text-align: center;
  color: #fff;
  background: #bbb;
  border: none;
  border-radius: 20px;
  outline: none;
}

.bs-ed-view-PictureItem .cimgrm:hover {
  background: #cd1e10;
}

.bs-ed-view-PictureItem .cimgrm:active {
  color: #e6e6e6;
  background: #9e170c;
}

.bs-ed-view-PictureItem:hover .cimgrm {
  display: block;
}

.bs-ed-view-PictureItem:first-child:last-child .cimgrm {
  display: none !important;
}

.bs-ed-view-PictureItem .cthumb {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #f6f6f7 linear-gradient(to bottom, #f6f6f7, #f1f1f2);
  font-weight: inherit;
  text-align: center;
  cursor: pointer;
}

.bs-ed-view-PictureItem .cthumb input {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.bs-ed-view-PictureItem .cthumb img {
  vertical-align: middle;
  max-width: 100%;
  max-height: 120px;
}

.bs-ed-view-PictureItem .cthumb .placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  vertical-align: middle;
  margin: -28px -30px;
  font-size: 12px;
  line-height: 32px;
  color: #bbb;
}

.has-img .bs-ed-view-PictureItem .cthumb .placeholder {
  display: none;
}

.bs-ed-view-PictureItem .cthumb .placeholder:before {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  font-family: "bs-ed-icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\F015";
  display: block;
  top: 5px;
  font-size: 32px;
}

.bs-ed-view-PictureItem .cthumb:hover {
  background: #f2f5fa linear-gradient(to bottom, #f2f5fa, #e7edf6);
}

.bs-ed-view-PictureItem .cthumb:hover .placeholder {
  color: #999;
}

.bs-ed-view-PictureItem .cthumb.bs-ed-drag-active::after {
  content: "ここに画像をドロップ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  font-size: 12px;
  font-weight: bold;
  line-height: 120px;
  color: #222;
  background: rgba(224, 224, 224, 0.8);
}

.bs-ed-view-PictureItem.has-img .cthumb .placeholder {
  display: none;
}

.bs-ed-view-PictureItem .ctool {
  position: relative;
  padding: 5px 27px 5px 10px;
}

.bs-ed-view-PictureItem .ccap {
  display: block;
  margin: 0;
  padding: 0;
  resize: none;
  overflow: hidden;
  width: 100%;
  height: 18px;
  font-size: 87.5%;
  text-align: center;
  line-height: 18px;
  border: none;
  outline: none;
}

.bs-ed-view-PictureItem .settings {
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  padding: 6px 0;
  text-align: center;
  color: #aaa;
  background: transparent;
  border: none;
}

.bs-ed-view-PictureItem .settings:hover {
  color: #333;
}

.bs-ed-view-PictureItem.has-link .settings {
  color: #0d95e8;
}

.bs-ed-preview-PictureC {
  margin: 15px -10px;
  text-align: center;
}

.bs-ed-preview-PictureC .ccnti {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

.bs-ed-preview-PictureC .bs-ed-preview-PictureItem {
  display: table-cell;
  padding: 0 10px;
  vertical-align: top;
}

.bs-ed-view-PictureC {
  position: relative;
  min-height: 124px;
}

.bs-ed-view-PictureC .ccnt {
  margin: 0 -4px;
  margin-right: 40px;
}

.bs-ed-view-PictureC .ccnti {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.bs-ed-view-PictureC .bs-ed-view-PictureItem {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 2px;
}

.bs-ed-view-PictureC .bs-ed-view-PictureItem > .ctool {
  flex: 1 0 auto;
}

.bs-ed-view-PictureC .cadd {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 38px;
  padding: 0;
}

.bs-ed-view-PictureC .caddb {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #777;
  border-radius: 0;
}

.bs-ed-view-PictureC .caddb:hover {
  color: #333;
}

.bs-ed-view-PictureC.full .caddb {
  color: #ccc !important;
  background: #f4f4f4 !important;
  border-color: #e4e4e4 !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: default !important;
}

.bs-ed-preview-Plaintext {
  margin: 5px 0;
}

.bs-ed-view-Plaintext textarea {
  width: 100%;
  height: 1.5em;
  min-height: 3em;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.5;
  vertical-align: top;
  border: none;
  resize: none;
  overflow: hidden;
}

.bs-ed-view-Plaintext textarea:focus {
  outline: none;
}

.no-textarea-adjust .bs-ed-view-Plaintext textarea {
  height: 7.5em;
  overflow-y: scroll;
}

.bs-ed-preview-FloatPicture {
  *zoom: 1;
  margin: 15px -10px;
}

.bs-ed-preview-FloatPicture:before,
.bs-ed-preview-FloatPicture:after {
  display: table;
  content: "";
  line-height: 0;
}

.bs-ed-preview-FloatPicture:after {
  clear: both;
}

.bs-ed-preview-FloatPicture + .bs-ed-preview-FloatPicture {
  margin-top: 30px;
}

.bs-ed-preview-FloatPicture .txt {
  margin: 0;
  padding: 0 10px;
  width: 66.66%;
}

.bs-ed-preview-FloatPicture .txt.right {
  float: left;
}

.bs-ed-preview-FloatPicture .txt.left {
  float: right;
}

.bs-ed-preview-FloatPicture .fig {
  width: 33.33%;
  padding: 0 10px;
  text-align: center;
}

.bs-ed-preview-FloatPicture .fig.right {
  float: right;
}

.bs-ed-preview-FloatPicture .fig.left {
  float: left;
}

.bs-ed-view-FloatPicture {
  position: relative;
  min-height: 148px;
}

.bs-ed-view-FloatPicture .ctxt {
  width: 100%;
}

.bs-ed-view-FloatPicture .ctxt > .bs-ed-view-Richtext-root {
  min-height: 144px;
}

.bs-ed-view-FloatPicture .cpic {
  position: absolute;
  top: 0;
  width: 220px;
}

.bs-ed-view-FloatPicture .ctgl {
  display: block;
  width: 29px;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 0;
  outline: none;
  color: #777;
  font-size: 0;
}

.bs-ed-view-FloatPicture .ctgl:hover {
  color: #333;
}

.bs-ed-view-FloatPicture .ctgl:before {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  font-family: "bs-ed-icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\F01D";
  font-size: 13px;
}

.bs-ed-view-FloatPicture.left {
  padding-left: 240px;
}

.bs-ed-view-FloatPicture.left .cpic {
  left: 0;
  padding-right: 35px;
}

.bs-ed-view-FloatPicture.left .ctgl {
  right: 0;
}

.bs-ed-view-FloatPicture.right {
  padding-right: 240px;
}

.bs-ed-view-FloatPicture.right .cpic {
  right: 0;
  padding-left: 35px;
}

.bs-ed-view-FloatPicture.right .ctgl {
  left: 0;
}

.bs-ed-preview-Richtext {
  margin: 5px 0;
}

.bs-ed-view-Richtext {
  position: relative;
  /*
	.tools {
		position: absolute; z-index: 99;
		display: none;
		padding: 4px;
		background: #ececea;
		border: solid 1px #d8d8d6; border-radius: 3px;
		box-shadow: 0 2px 5px fade(#000, 20%);

		&.on {
			display: inline-block;
		}

		// button
		.bs-ed-act {
			padding: 6px 5px;
			color: #333; background: transparent;
			border: none;
			border-radius: 3px;

			&:active {
				background: fade(#000, 20%);
			}
		}
	}
	  */
}

.bs-ed-view-Richtext .bs-ed-root {
  min-height: 1.5em;
  font-size: 15px;
  line-height: 1.5;
  cursor: text;
  outline: none;
}

.bs-ed-preview-Heading,
.bs-ed-view-Heading {
  position: relative;
  margin: 20px 0 5px;
  font-size: 120%;
  line-height: 1.2;
  font-weight: bold;
}

.bs-ed-view-Heading {
  margin: 0;
  padding-bottom: 0;
}

.bs-ed-view-Heading textarea {
  width: 100%;
  height: 1.2em;
  line-height: 1.2;
  margin: 0;
  padding: 5px 0;
  border: none;
  resize: none;
  overflow: hidden;
  border-bottom: solid 2px #999;
}

.bs-ed-view-Heading textarea:focus {
  outline: none;
}

.bs-ed-preview-Subheading,
.bs-ed-view-Subheading {
  position: relative;
  margin: 20px 0 10px;
  font-size: 112%;
  line-height: 1.2;
  font-weight: bold;
  color: #666;
}

.bs-ed-view-Subheading {
  margin: 0;
  padding-bottom: 0;
}

.bs-ed-view-Subheading textarea {
  width: 100%;
  height: 1.2em;
  line-height: 1.2;
  margin: 0;
  padding: 5px 0;
  border: none;
  resize: none;
  overflow: hidden;
  border-bottom: dashed 1px #ddd;
  color: #666;
}

.bs-ed-view-Subheading textarea:focus {
  outline: none;
}

.bs-ed-preview-Table {
  border-collapse: collapse;
  margin: 15px 0;
}

.bs-ed-preview-Table th,
.bs-ed-preview-Table td {
  vertical-align: middle;
  padding: 7px 10px;
  border: solid 1px #ccc;
  background: #fff;
}

.bs-ed-preview-Table th {
  font-weight: normal;
  background: #f1f2f5;
}

.bs-ed-view-Table .tool {
  margin: 2px 0 0;
  opacity: 0.6;
}

.bs-ed-view-Table:hover .tool {
  opacity: 1;
}

.bs-ed-view-TableRow {
  display: table;
  width: 100%;
}

.bs-ed-view-TableRow .th,
.bs-ed-view-TableRow .td {
  display: table-cell;
  vertical-align: top;
  padding: 5px;
  border: solid 1px #ccc;
}

.bs-ed-view-TableRow .th:hover,
.bs-ed-view-TableRow .td:hover {
  border-color: #aaa;
}

.bs-ed-view-TableRow .th {
  width: 25%;
  background: #f1f2f5;
}

.bs-ed-view-TableRow .td {
  background: #fff;
}

.header > .bs-ed-view-TableRow .th,
.header > .bs-ed-view-TableRow .td {
  background: #f1f2f5;
}

.bs-ed-view-TableRow textarea {
  display: block;
  width: 100%;
  height: 1.5em;
  min-height: 1.5em;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  border: none;
  resize: none;
  overflow: hidden;
}

.bs-ed-view-TableRow textarea:focus {
  outline: none;
}

.bs-ed-view-TableRow .row-tool {
  display: table-cell;
  vertical-align: top;
  width: 53px;
  padding: 3px 0 0 2px;
  opacity: 0;
}

.bs-ed-view-TableRow:hover .row-tool {
  opacity: 1;
}

.bs-ed-view-TableRow:first-child:last-child:hover .row-tool > * {
  display: none;
}

.bs-ed-preview-Tabular {
  border-collapse: collapse;
  margin: 15px 0;
}

.bs-ed-preview-Tabular th,
.bs-ed-preview-Tabular td {
  vertical-align: middle;
  padding: 7px 10px;
  border: solid 1px #ccc;
  background: #fff;
}

.bs-ed-preview-Tabular th {
  font-weight: normal;
  background: #f1f2f5;
}

.bs-ed-preview-Tabular th p,
.bs-ed-preview-Tabular td p,
.bs-ed-preview-Tabular th ul,
.bs-ed-preview-Tabular td ul,
.bs-ed-preview-Tabular th ol,
.bs-ed-preview-Tabular td ol {
  margin-top: 0;
  margin-bottom: 0;
}

.bs-ed-preview-Tabular th ul,
.bs-ed-preview-Tabular td ul,
.bs-ed-preview-Tabular th ol,
.bs-ed-preview-Tabular td ol {
  margin-left: 1.5em;
}

.bs-ed-view-Tabular .bs-ed-tbrows {
  padding-bottom: 1px;
}

.bs-ed-view-Tabular .bs-ed-tbrow {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: -1px;
  position: relative;
}

.bs-ed-view-Tabular .bs-ed-tbcol {
  display: table-cell;
  vertical-align: top;
  width: 100%;
  border: solid 1px #bbb;
  background: #fff;
}

.bs-ed-view-Tabular .bs-ed-tbth {
  background: #f1f2f5;
}

.bs-ed-view-Tabular .bs-ed-tbrtool {
  vertical-align: top;
  width: 0;
}

.bs-ed-view-Tabular .bs-ed-tbrtooli {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 0 0 2px;
}

.bs-ed-view-Tabular .bs-ed-tbrtooli .bs-ed-danger {
  position: absolute;
  left: 100%;
  margin-left: 4px;
}

.bs-ed-view-Tabular .bs-ed-tbredit {
  padding-right: 14px;
}

.bs-ed-view-Tabular .bs-ed-tbredit .bs-ed-tbrtooli {
  display: block;
  cursor: move;
}

.bs-ed-view-Tabular .bs-ed-tbghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.bs-ed-view-Tabular .bs-ed-tbtool {
  margin: 0.375em 0 0;
  opacity: 0.6;
}

.bs-ed-view-Tabular .bs-ed-tbtool .bs-ed-btn {
  margin-right: 0.375em;
}

.bs-ed-view-Tabular:hover .bs-ed-tbtool {
  opacity: 1;
}

.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root {
  padding: 0.375em 0.5em;
  min-height: 2.25em;
}

.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root p,
.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root ul,
.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root ol {
  margin-top: 0;
  margin-bottom: 0;
}

.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root ul,
.bs-ed-view-Tabular .bs-ed-tbcol .bs-ed-root ol {
  margin-left: 1.5em;
}

.bs-ed-preview-File {
  margin: 10px 0;
  padding: 8px 10px;
  padding-left: 1em;
}

.bs-ed-preview-File:before {
  content: "ファイルをダウンロード";
  display: block;
  font-size: 87.5%;
  font-weight: bold;
}

.bs-ed-preview-File a:before {
  content: "・";
}

.bs-ed-preview-File + .bs-ed-preview-File {
  margin-top: -14px;
  padding-top: 0;
}

.bs-ed-preview-File + .bs-ed-preview-File:before {
  content: none;
  display: none;
}

.bs-ed-preview-FileList {
  margin: 10px 0;
  padding: 8px 10px;
  background: #f8f8f8;
}

.bs-ed-preview-FileList .hd {
  display: inline-block;
  margin: 0 0 5px;
  padding: 3px 5px;
  line-height: 1.2;
  color: #fff;
  background: #979eb5;
  border-radius: 2px;
}

.bs-ed-preview-FileList ul {
  margin: 0;
  list-style: disc;
}

.bs-ed-view-FileList {
  padding: 7px 5px;
  background: #f8f8f8;
  border-radius: 3px;
}

.bs-ed-view-FileList .hd {
  display: inline-block;
  margin: 0 0 3px;
  padding: 2px 5px;
  font-size: 87.5%;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  background: #979eb5;
  border-radius: 3px;
}

.bs-ed-view-FileList .bs-ed-view-File {
  margin-bottom: 3px;
}

.bs-ed-view-File {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
  padding: 0 0 0 10px;
  border-radius: 3px;
}

.bs-ed-view-File:before {
  content: "";
  width: 7px;
  height: 1px;
  margin-right: 8px;
  background: #777;
}

.bs-ed-view-File .llfi {
  flex: 1;
}

.bs-ed-view-File .llfn,
.bs-ed-view-File .llfp,
.bs-ed-view-File .llfc {
  display: none;
}

.bs-ed-view-File .llfn {
  flex: 1;
  margin-right: 3px;
}

.bs-ed-view-File .llfp,
.bs-ed-view-File .llfc {
  margin-left: 3px;
}

.llaf .bs-ed-view-File.bs-ed-drag-active::after {
  content: "ここにファイルをドロップ";
  display: block;
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  color: #222;
  background: rgba(231, 237, 246, 0.8);
}

.bs-ed-view-File .tool {
  width: 53px;
  margin-left: 3px;
  padding: 3px 3px 0 0;
  text-align: right;
  opacity: 0;
  visibility: hidden;
}

.bs-ed-view-File .llfp,
.bs-ed-view-File .llfc,
.bs-ed-view-File .tool {
  opacity: 0.6;
  transition: opacity 0.15s linear;
}

.bs-ed-view-File .tool {
  opacity: 0;
}

.bs-ed-view-File:hover .llfp,
.bs-ed-view-File:hover .llfc,
.bs-ed-view-File:hover .tool {
  opacity: 1;
  transition: none;
}

.bs-ed-view-File.has-file .llfi {
  display: none;
}

.bs-ed-view-File.has-file .llfn,
.bs-ed-view-File.has-file .llfp,
.bs-ed-view-File.has-file .llfc {
  display: block;
}

.bs-ed-view-File.has-file:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.bs-ed-view-File.has-file .tool {
  visibility: visible;
}

.bs-ed-view-File:first-child:last-child .bs-ed-editor-sort-handle {
  visibility: hidden;
}

.bs-ed-preview-LinkList {
  margin: 10px 0;
  padding: 8px 10px;
  background: #fdf2f4;
}

.bs-ed-preview-LinkList .hd {
  display: inline-block;
  margin: 0 0 5px;
  padding: 3px 5px;
  line-height: 1.2;
  color: #fff;
  background: #d82d47;
  border-radius: 2px;
}

.bs-ed-preview-LinkList ul {
  margin: 0;
  list-style: disc;
}

.bs-ed-view-LinkList {
  padding: 5px;
  background: #fdf2f4;
  border-radius: 3px;
}

.bs-ed-view-LinkList .hd {
  display: inline-block;
  margin: 0 0 3px;
  padding: 2px 5px;
  font-size: 87.5%;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  background: #d82d47;
  border-radius: 3px;
}

.bs-ed-view-LinkList .bs-ed-view-Link {
  margin-bottom: 3px;
}

.bs-ed-view-Link {
  position: relative;
  display: table;
  width: 100%;
  background: #f6f6f7 linear-gradient(to bottom, #f6f6f7, #f1f1f2);
  border: solid 1px #d8d8d6;
}

.bs-ed-view-Link .icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 52px;
}

.bs-ed-view-Link .icon:before {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  font-family: "bs-ed-icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\F011";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -12px;
  font-size: 24px;
  color: #bbb;
}

.bs-ed-view-Link .input {
  display: table-cell;
  vertical-align: middle;
  padding: 5px 2px 5px 0;
}

.bs-ed-view-Link .form-control {
  background: #fff;
}

.bs-ed-view-Link .input-url {
  height: 26px;
  margin-top: 2px;
  padding: 4px 10px;
  font-size: 11px;
  line-height: 1.4;
  border-radius: 3px;
}

.bs-ed-view-Link .tool {
  display: table-cell;
  vertical-align: middle;
  width: 53px;
  padding: 3px 3px 0 0;
  opacity: 0;
}

.bs-ed-view-Link:hover .tool {
  opacity: 1;
}

.bs-ed-view-Link:first-child:last-child .tool > * {
  display: none;
}

.bs-ed-preview-LinkButton {
  margin: 15px 0;
  text-align: center;
}

.bs-ed-preview-LinkButton .btn {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.3em;
  padding: 0.375em 1em;
  border: solid 1px;
  color: #fff;
  font-size: 130.6%;
  min-width: 55%;
}

.bs-ed-preview-LinkButton .btn:disabled,
.bs-ed-preview-LinkButton .btn.disabled {
  cursor: default;
  opacity: 0.6;
}

.bs-ed-preview-LinkButton .btn:focus,
.bs-ed-preview-LinkButton .btn.focus {
  outline: 0;
}

.bs-ed-preview-LinkButton .btn,
.bs-ed-preview-LinkButton .btn:disabled,
.bs-ed-preview-LinkButton .btn.disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.bs-ed-preview-LinkButton .btn:disabled,
.bs-ed-preview-LinkButton .btn.disabled {
  color: #e4e4e4;
}

.bs-ed-preview-LinkButton .btn:hover,
.bs-ed-preview-LinkButton .btn:focus {
  color: #fff;
  background-color: #0d95e8;
  border-color: #0d95e8;
}

.bs-ed-preview-LinkButton .btn:active,
.bs-ed-preview-LinkButton .btn.active {
  color: #d9d9d9;
  background-color: #0b7fc6;
  border-color: #0b7fc6;
}

.bs-ed-view-LinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bs-ed-view-LinkButton-btn {
  margin-left: 24px;
  margin-right: 4px;
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.3em;
  padding: 0.375em 1em;
  border: solid 1px;
  color: #fff;
  font-size: 130.6%;
  min-width: 55%;
}

.bs-ed-view-LinkButton-btn:disabled,
.bs-ed-view-LinkButton-btn.disabled {
  cursor: default;
  opacity: 0.6;
}

.bs-ed-view-LinkButton-btn:focus,
.bs-ed-view-LinkButton-btn.focus {
  outline: 0;
}

.bs-ed-view-LinkButton-btn,
.bs-ed-view-LinkButton-btn:disabled,
.bs-ed-view-LinkButton-btn.disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.bs-ed-view-LinkButton-btn:disabled,
.bs-ed-view-LinkButton-btn.disabled {
  color: #e4e4e4;
}

.bs-ed-view-LinkButton-btn:hover,
.bs-ed-view-LinkButton-btn:focus {
  color: #fff;
  background-color: #0d95e8;
  border-color: #0d95e8;
}

.bs-ed-view-LinkButton-btn:active,
.bs-ed-view-LinkButton-btn.active {
  color: #d9d9d9;
  background-color: #0b7fc6;
  border-color: #0b7fc6;
}

.bs-ed-view-LinkButton-tool {
  width: 20px;
}

.bs-ed-view-LinkButton-tool .bs-ed-btn-tr {
  padding: 0.25em;
}

.bs-ed-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.bs-ed-root a {
  color: #05b;
  text-decoration: underline;
  cursor: text;
}

.bs-ed-root .heading:first-child,
.bs-ed-root .subhead:first-child,
.bs-ed-root .subsubhead:first-child,
.bs-ed-root p:first-child {
  margin-top: 0;
}

.bs-ed-root .heading:last-child,
.bs-ed-root .subhead:last-child,
.bs-ed-root .subsubhead:last-child,
.bs-ed-root p:last-child {
  margin-bottom: 0;
}

.bs-ed-root .heading {
  margin: 1.2em 0 0.8em;
  padding: 0.4em 0.5em;
  font-size: 125%;
  font-weight: 700;
  color: #fff;
  background: #428bca;
  border-radius: 6px;
}

.bs-ed-root .subhead {
  margin: 1.38888889em 0 0.92592593em;
  padding: 0.4em 0.5em;
  font-size: 108%;
  font-weight: 700;
  color: #333;
  background: #ebf1f9;
  border: none;
  border-bottom: solid 1px #e6e6e6;
  border-left: solid 5px #75ade6;
}

.bs-ed-root .subsubhead {
  margin: 1.5em 0 1em;
  padding: 0.25em 0;
  font-size: 100%;
  font-weight: 700;
  color: #333;
  background: transparent;
  border: none;
  border-bottom: solid 1px #ddd;
}

.bs-ed-root p {
  margin: 1em 0;
  padding: 0;
  font-size: 100%;
  font-weight: 400;
  border: none;
}

.bs-ed-root ul,
.bs-ed-root ol {
  margin: 1em 0 1em 2em;
}

.bs-ed-root ul {
  list-style: disc;
}

.bs-ed-root ol {
  list-style: decimal;
}

.bs-ed-view-Anchor-wrap {
  display: inline-block;
  vertical-align: top;
  padding: 1px;
  color: #fff;
  background: #787c7e;
  border-radius: 5px;
  overflow: hidden;
  line-height: 1.5;
  font-weight: 400;
}

.bs-ed-view-Anchor-wrap > span,
.bs-ed-view-Anchor-wrap > input {
  padding: 2px 4px;
}

.bs-ed-view-Anchor-wrap > span {
  padding-right: 0;
  opacity: 0.6;
}

.bs-ed-view-Anchor-wrap > input {
  box-sizing: border-box;
  width: 150px;
  color: inherit;
  background: transparent;
  border: none;
}

.bs-ed-view-Anchor-wrap > input:focus {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
}

.bs-ed-view-Anchor .bs-ed-btn-tr {
  padding: 2px 4px;
  opacity: 0;
}

.bs-ed-edititem.hover .bs-ed-view-Anchor .bs-ed-btn-tr,
.bs-ed-edititem.focus .bs-ed-view-Anchor .bs-ed-btn-tr {
  opacity: 1;
}

.bs-ed-preview-YouTube {
  margin: 15px 0;
  text-align: center;
}

.bs-ed-view-YouTube.llv .lli .bs-ed-icon {
  color: #f22;
  opacity: 1;
}

.bs-ed-view-YouTube .lli {
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #ddd;
  background: #fff;
}

.bs-ed-view-YouTube .lli .bs-ed-icon {
  padding: 3px;
  padding-right: 0;
  font-size: 18px;
  opacity: 0.8;
}

.bs-ed-view-YouTube .lli .bs-ed-input-tr {
  flex: 1;
  padding: 5px 8px;
}

.bs-ed-view-YouTube .llp {
  position: relative;
  text-align: center;
  height: 200px;
  background: #444 none no-repeat 50% 50% / contain;
}

.bs-ed-view-YouTube .llph {
  display: none;
}

.bs-ed-view-YouTube .llh {
  margin-top: 3px;
  font-weight: 700;
  color: #ed4f43;
}

.bs-ed-preview-Map {
  width: 100%;
  height: 220px;
  margin: 15px 0;
}

.bs-ed-preview-Map > .bs-replace-map {
  height: 100%;
}

.bs-ed-view-Map {
  position: relative;
  width: 100%;
  height: 220px;
}

.bs-ed-view-Map > .map {
  width: 100%;
  height: 100%;
  border: solid 1px #ddd;
  background: #f8f8f8;
}

.bs-ed-view-Map > .map-tools {
  position: absolute;
  right: 9px;
  top: 9px;
  width: auto;
}

.bs-ed-view-Map > .map-tools .bs-ed-btn {
  font-size: 13px;
  line-height: 1;
  padding: 9px 9px 7px;
}

.bs-ed-preview-plugin {
  margin: 15px auto;
  text-align: center;
}

.bs-ed-preview-plugin iframe {
  max-width: 100%;
}

.bs-ed-view-GMap.llv .lli .bs-ed-icon {
  color: #1da261;
  opacity: 1;
}

.bs-ed-view-GMap .lli {
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #ddd;
  background: #fff;
}

.bs-ed-view-GMap .lli .bs-ed-icon {
  padding: 3px;
  padding-right: 0;
  font-size: 18px;
  opacity: 0.8;
}

.bs-ed-view-GMap .lli .bs-ed-input-tr {
  flex: 1;
  padding: 5px 8px;
}

.bs-ed-view-GMap .llp {
  position: relative;
  text-align: center;
  min-height: 80px;
  max-height: 250px;
  overflow: auto;
  border: dashed 2px #e4e4e4;
}

.bs-ed-view-GMap .llp > iframe {
  vertical-align: top;
  max-width: 100%;
}

.bs-ed-view-GMap .llp:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bs-ed-view-GMap .llph {
  display: none;
}

.bs-ed-view-GMap .llh {
  margin-top: 3px;
  font-weight: 700;
  color: #ed4f43;
}

.bs-ed-preview-Twitter {
  max-width: 600px;
}

.bs-ed-view-Twitter.llv .lli .bs-ed-icon {
  color: #1ea1f2;
  opacity: 1;
}

.bs-ed-view-Twitter .lli {
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #ddd;
  background: #fff;
}

.bs-ed-view-Twitter .lli .bs-ed-icon {
  padding: 3px;
  padding-right: 0;
  font-size: 18px;
  opacity: 0.8;
}

.bs-ed-view-Twitter .lli .bs-ed-input-tr {
  flex: 1;
  padding: 5px 8px;
}

.bs-ed-view-Twitter .llp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
  color: #777;
  background: #f4f4f4;
  cursor: default;
  user-select: none;
}

.bs-ed-view-Twitter .llp .bs-ed-icon {
  margin: -4px 0 0;
  width: 80px;
  height: 80px;
  font-size: 80px;
  line-height: 1;
  color: #ccc;
}

.bs-ed-view-Twitter .llph {
  display: none;
}

.bs-ed-view-Twitter .llh {
  margin-top: 3px;
  font-weight: 700;
  color: #ed4f43;
}

.bs-ed-preview-Facebook {
  margin: 15px 0;
  text-align: center;
}

.bs-ed-view-Facebook.llv .lli .bs-ed-icon {
  color: #1877f2;
  opacity: 1;
}

.bs-ed-view-Facebook .lli {
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #ddd;
  background: #fff;
}

.bs-ed-view-Facebook .lli .bs-ed-icon {
  padding: 3px;
  padding-right: 0;
  font-size: 18px;
  opacity: 0.8;
}

.bs-ed-view-Facebook .lli .bs-ed-input-tr {
  flex: 1;
  padding: 5px 8px;
}

.bs-ed-view-Facebook .llp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
  color: #777;
  background: #f4f4f4;
  cursor: default;
  user-select: none;
}

.bs-ed-view-Facebook .llp .bs-ed-icon {
  margin: -4px 0 0;
  width: 80px;
  height: 80px;
  font-size: 80px;
  line-height: 1;
  color: #ccc;
}

.bs-ed-view-Facebook .llph {
  display: none;
}

.bs-ed-view-Facebook .llh {
  margin-top: 3px;
  font-weight: 700;
  color: #ed4f43;
}

@media (min-width: 768px) {
  .bs-ed-form-group {
    display: flex;
  }

  .bs-ed-form-label {
    flex: 0 0 auto;
    width: 20%;
    min-height: 1px;
    margin-bottom: 0;
    padding: 0.375em 0 0;
    text-align: right;
  }

  .bs-ed-form-controls {
    flex: 0 1 auto;
    width: 80%;
    min-height: 1px;
    padding-left: 1em;
  }

  .bs-ed-dialog-title,
  .bs-ed-dialog-subtitle {
    margin: 18px 20px;
  }

  .bs-ed-dialog-actions {
    padding: 12px 20px;
  }

  .bs-ed-dialog-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 640px) {
  .bs-ed-mobile-edit {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background: rgba(238, 238, 238, 0.25);
    cursor: pointer;
  }

  .bs-ed-mobile-edit-enable .bs-ed-mobile-edit {
    display: none;
  }

  .bs-ed-toolbar-wrap {
    display: none;
  }

  html.bs-ed-mobile-edit-enable,
  html.bs-ed-mobile-edit-enable > body {
    overflow: hidden;
    height: 100%;
  }

  .bs-ed-view-Editor.bs-ed-mobile-edit-enable {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    height: auto;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .bs-ed-view-Editor.bs-ed-mobile-edit-enable > .bs-ed-toolbar-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  .bs-ed-view-Editor.bs-ed-mobile-edit-enable .bs-ed-mobile-tool {
    display: block;
  }

  .bs-ed-view-Editor.bs-ed-mobile-edit-enable > .bs-ed-content-scroll {
    display: block;
    flex: 1 0 auto;
    overflow-y: auto;
  }

  .bs-ed-view-Editor.bs-ed-mobile-edit-enable .bs-ed-content-wrap {
    height: auto;
  }

  .bs-ed-content-wrap:after {
    content: none;
    display: none;
  }

  .bs-ed-edititem {
    margin-right: 0;
    padding-right: 7px;
  }

  .bs-ed-edititem-tool {
    opacity: 1;
    top: 4.5px;
  }

  .bs-ed-edititem-tool .bs-ed-editor-sort-handle {
    display: none;
  }

  .bs-ed-inserter-active .bs-ed-edititem-tool {
    opacity: 0 !important;
  }

  .bs-ed-inserter-cnt {
    visibility: visible !important;
  }

  .bs-ed-inserter-btns {
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
  }

  .bs-ed-inserter-btns > .bs-ed-inserter-btn {
    flex: 0 1 auto;
    width: 32px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }

  .bs-ed-view-FloatPicture.left {
    padding-left: 160px;
  }

  .bs-ed-view-FloatPicture.right {
    padding-right: 160px;
  }

  .bs-ed-view-FloatPicture .cpic {
    width: 150px;
  }

  .bs-ed-view-PictureItem .cimgrm.cimgrm {
    display: block;
  }

  .bs-ed-view-TableRow .row-tool {
    width: 28px;
    opacity: 1;
  }

  .header .bs-ed-view-TableRow .row-tool {
    opacity: 0;
  }

  .bs-ed-view-TableRow .bs-ed-editor-sort-handle {
    display: none;
  }

  .bs-ed-view-File .thumb {
    width: 72px;
  }

  .bs-ed-view-File .tool {
    width: 28px;
    opacity: 1;
  }

  .bs-ed-view-File .bs-ed-editor-sort-handle {
    display: none;
  }
}

@media (min-height: 700px) {
  .bs-ed-content-scroll,
  .bs-ed-content-wrap {
    height: calc(50vh);
  }
}