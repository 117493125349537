@bs-ed-icon-font-path: "../fonts/";

@font-face {
	font-family: "bs-ed-icon";
	src: url("@{bs-ed-icon-font-path}bs-ed-icon.woff?v=1613982441007") format("woff"),
		url("@{bs-ed-icon-font-path}bs-ed-icon.woff2?v=1613982441007") format("woff2");
	font-weight: normal;
	font-style: normal;
}

.apply-bs-ed-icon() {
	display: inline-block;
	vertical-align: middle;
	speak: none;
	font-family: "bs-ed-icon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	text-decoration: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.bs-ed-icon {
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
}

.bs-ed-icon:before {
	.apply-bs-ed-icon;
}

.bs-ed-icon-lg {
	font-size: 1.333em;
	line-height: .75em;
	vertical-align: -15%;
}
.bs-ed-icon-2x { font-size: 200%; }
.bs-ed-icon-3x { font-size: 300%; }
.bs-ed-icon-4x { font-size: 400%; }
.bs-ed-icon-5x { font-size: 500%; }

@bs-ed-icon-big: "\F001";
@bs-ed-icon-bold: "\F002";
@bs-ed-icon-button: "\F003";
@bs-ed-icon-code: "\F004";
@bs-ed-icon-cog: "\F005";
@bs-ed-icon-color: "\F006";
@bs-ed-icon-copy: "\F007";
@bs-ed-icon-dots: "\F008";
@bs-ed-icon-edit: "\F009";
@bs-ed-icon-facebook: "\F00A";
@bs-ed-icon-file: "\F00B";
@bs-ed-icon-float: "\F00C";
@bs-ed-icon-hash: "\F00D";
@bs-ed-icon-heading1: "\F00E";
@bs-ed-icon-heading2: "\F00F";
@bs-ed-icon-heading3: "\F010";
@bs-ed-icon-link: "\F011";
@bs-ed-icon-list-bullet: "\F012";
@bs-ed-icon-list-ordered: "\F013";
@bs-ed-icon-map: "\F014";
@bs-ed-icon-picture: "\F015";
@bs-ed-icon-plus: "\F016";
@bs-ed-icon-remove: "\F017";
@bs-ed-icon-search: "\F018";
@bs-ed-icon-small: "\F019";
@bs-ed-icon-strike: "\F01A";
@bs-ed-icon-table: "\F01B";
@bs-ed-icon-text: "\F01C";
@bs-ed-icon-toggle-h: "\F01D";
@bs-ed-icon-toggle-v: "\F01E";
@bs-ed-icon-twitter: "\F01F";
@bs-ed-icon-underline: "\F020";
@bs-ed-icon-video: "\F021";

.bs-ed-icon-big:before { content: "\F001" }
.bs-ed-icon-bold:before { content: "\F002" }
.bs-ed-icon-button:before { content: "\F003" }
.bs-ed-icon-code:before { content: "\F004" }
.bs-ed-icon-cog:before { content: "\F005" }
.bs-ed-icon-color:before { content: "\F006" }
.bs-ed-icon-copy:before { content: "\F007" }
.bs-ed-icon-dots:before { content: "\F008" }
.bs-ed-icon-edit:before { content: "\F009" }
.bs-ed-icon-facebook:before { content: "\F00A" }
.bs-ed-icon-file:before { content: "\F00B" }
.bs-ed-icon-float:before { content: "\F00C" }
.bs-ed-icon-hash:before { content: "\F00D" }
.bs-ed-icon-heading1:before { content: "\F00E" }
.bs-ed-icon-heading2:before { content: "\F00F" }
.bs-ed-icon-heading3:before { content: "\F010" }
.bs-ed-icon-link:before { content: "\F011" }
.bs-ed-icon-list-bullet:before { content: "\F012" }
.bs-ed-icon-list-ordered:before { content: "\F013" }
.bs-ed-icon-map:before { content: "\F014" }
.bs-ed-icon-picture:before { content: "\F015" }
.bs-ed-icon-plus:before { content: "\F016" }
.bs-ed-icon-remove:before { content: "\F017" }
.bs-ed-icon-search:before { content: "\F018" }
.bs-ed-icon-small:before { content: "\F019" }
.bs-ed-icon-strike:before { content: "\F01A" }
.bs-ed-icon-table:before { content: "\F01B" }
.bs-ed-icon-text:before { content: "\F01C" }
.bs-ed-icon-toggle-h:before { content: "\F01D" }
.bs-ed-icon-toggle-v:before { content: "\F01E" }
.bs-ed-icon-twitter:before { content: "\F01F" }
.bs-ed-icon-underline:before { content: "\F020" }
.bs-ed-icon-video:before { content: "\F021" }
