// ================================================================
// LinkButton

.bs-ed-LinkButton-style() {
	.bs-ed-btn();
	.bs-ed-btn-primary();
	font-size: 130.6%;
	min-width: 55%;
}

.bs-ed-preview-LinkButton:extend(.bs-ed-preview-PictureB all) {
	margin: 15px 0;
	text-align: center;

	.btn {
		.bs-ed-LinkButton-style();
	}
}

.bs-ed-view-LinkButton {
	display: flex;
	justify-content: center;
	align-items: center;

	&-btn {
		margin-left: 24px;
		margin-right: 4px;
		.bs-ed-LinkButton-style();
	}

	&-tool {
		width: 20px;

		.bs-ed-btn-tr {
			padding: .25em;
		}
	}
}

