// ================================================================
// ファイル

.bs-ed-preview-File {
	margin: 10px 0;
	padding: 8px 10px;
	padding-left: 1em;

	&:before { content: "ファイルをダウンロード"; display: block; font-size: 87.5%; font-weight: bold; }
	a:before { content: "・"; }
	
	& + & {
		margin-top: -14px; padding-top: 0;
		&:before { content: none; display: none; }
	}
}

.bs-ed-preview-FileList {
	margin: 10px 0; padding: 8px 10px;
	background: @bs-ed-filelist-bg;

	.hd {
		display: inline-block;
		margin: 0 0 5px;
		padding: 3px 5px;
		line-height: 1.2;
		color: @bs-ed-filelist-hd-fg; background: @bs-ed-filelist-hd-bg;
		border-radius: 2px;
	}
	
	ul {
		margin: 0;
		list-style: disc;
	}
}

.bs-ed-view-FileList {
	padding: 7px 5px;
	background: @bs-ed-filelist-bg;
	border-radius: 3px;
	
	.hd {
		display: inline-block;
		margin: 0 0 3px;
		padding: 2px 5px;
		font-size: 87.5%; font-weight: bold; line-height: 1.2;
		color: @bs-ed-filelist-hd-fg;
		background: @bs-ed-filelist-hd-bg;
		border-radius: 3px;
	}
	
	.bs-ed-view-File {
		margin-bottom: 3px;
	}
}

.bs-ed-view-File {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 32px;
	padding: 0 0 0 10px;
	border-radius: 3px;

	&:before {
		content: "";
		width: 7px;
		height: 1px;
		margin-right: 8px;
		background: #777;
	}

	.llfi {
		flex: 1;
	}

	.llfn,
	.llfp,
	.llfc {
		display: none;
	}

	.llfn {
		flex: 1;
		margin-right: 3px;
	}

	.llfp,
	.llfc {
		margin-left: 3px;
	}
	
	// ドラッグ中のスタイル
	.llaf &.bs-ed-drag-active {
		&::after {
			content: "ここにファイルをドロップ";
			display: block;
			position: absolute;
			z-index: 20;
			.pos(0);
			font-size: 12px;
			line-height: 32px;
			text-align: center;
			color: #222;
			background: fade(#e7edf6, 80%);
		}
	}
	
	.tool {
		width: 53px;
		margin-left: 3px;
		padding: 3px 3px 0 0;
		text-align: right;
		opacity: 0;
		visibility: hidden;
	}

	.llfp,
	.llfc,
	.tool {
		opacity: .6;
		transition: opacity .15s linear;
	}
	.tool {
		opacity: 0;
	}

	&:hover {
		.llfp,
		.llfc,
		.tool {
			opacity: 1;
			transition: none;
		}
	}

	// ファイル設定済み
	&.has-file {
		.llfi {
			display: none;
		}
		.llfn,
		.llfp,
		.llfc {
			display: block;
		}
		&:hover {
			background-color: fade(#000, 4%);
		}

		.tool {
			visibility: visible;
		}
	}

	// 項目が1つしかない場合は「ソート」は不要
	&:first-child:last-child .bs-ed-editor-sort-handle {
		visibility: hidden;
	}
}

