// ================================================================
// Toolbar

.bs-ed-toolbar-wrap {
	display: flex;
	padding: 3px 2px;
	color: @bs-ed-ui-fg; background: @bs-ed-ui-bg;
	border-bottom: solid 1px @bs-ed-ui-border;
}

.bs-ed-toolbar {
	flex: 1 1 auto;
}

.bs-ed-toolbar-grp {
	display: inline-block;
	vertical-align: bottom;

	&:after {
		display: inline-block;
		vertical-align: bottom;
		content: "";
		margin: 0 2px;
		width: 1px; height: 32px;
		background: #ddd;
	}

	&:last-child:after {
		content: none;
	}
}

.bs-ed-toolbar-item {
	position: relative;
	display: inline-block; vertical-align: bottom;
	margin: 0 1px;
}

.bs-ed-toolbar-btn {
	display: inline-block; vertical-align: bottom;
	height: 32px;
	line-height: 1;
	padding: 7px 8px;
	white-space: nowrap;

	color: @bs-ed-ui-fg;
	background: @bs-ed-ui-bg;
	border: solid 1px transparent;
	border-radius: 3px;
	transition: border-color .15s linear, background-color .15s linear;

	&,
	.bs-ed-wrap & {
		font-size: 16px;
	}

	& + & {
		margin-left: 0;
	}

	&:hover,
	&:focus {
		background-color: #f8f8f8;
		border-color: @bs-ed-ui-border;
	}

	&:active,
	&.active {
		border-color: @bs-ed-ui-border;
		background-color: @bs-ed-ui-border;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, .15);
		transition: none;
	}

	.bs-ed-toolbar.disabled & {
		color: @bs-ed-ui-disable-fg;
		cursor: default;

		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}
	}

	.bs-ed-ic {
		font-size: 16px;
	}
}

// ================================================================
// Toolbar Dropdown

.bs-ed-toolbar-dropdown {
	display: none;

	position: absolute;
	left: 0; top: 32px; z-index: 50;

	background: @bs-ed-ui-bg;
	border: solid 1px @bs-ed-ui-border;
	border-radius: 3px;
	box-shadow: 0 1px 3px fade(#000, 15);

	&.bs-ed-active {
		display: block;
	}
}

// ================================================================
// Plugins

// textcolor
.bs-ed-textcolor-dropdown {
	width: 118px;
	padding: 2px;
}

.bs-ed-textcolor-dropdown > .bs-ed-toolbar-item {
	margin: 0;
}

.bs-ed-textcolor-dropdown-item {
	display: inline-block; vertical-align: top;
	margin: 2px;
	width: 24px; height: 24px;
	border: solid 1px @bs-ed-ui-border;
	border-radius: 2px;

	cursor: pointer;
}
