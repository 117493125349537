// ================================================================
// エディタ

.bs-ed-view-Editor {
	display: block; width: 100%;
	position: relative;
	overflow: hidden;

	font-size: 14px; line-height: 1.42857143;
	color: #555; background: #f7f7f6;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

// エディタの高さを設定
.bs-ed-content-scroll,
.bs-ed-content-wrap {
	height: 350px;
	@media (min-height: 700px) {
		height: ~"calc(50vh)";
	}
}

.bs-ed-content-scroll {
	overflow: auto; // スクロールバーを表示
	-webkit-overflow-scrolling: touch; // 慣性スクロールを有効にする
}

.bs-ed-content-wrap {
	display: table;
	width: 100%;

	// ブロックの削除・並べ替え部分
	// .bs-ed-content-wrap と高さを合わせる
	&:after {
		content: "";
		display: table-cell;
		width: 65px;
	}
}

.bs-ed-content {
	position: relative;
	display: table-cell;
	padding: 4px;
	background: #fff;
	border-right: solid 1px #d8d8d6;
	border-radius: 4px 0 0 4px; // 外側に合わせる
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075); // 外側に合わせる
}

// ブロックの削除・並べ替え部分の内容
.bs-ed-edititem-tool {
	position: absolute;
	top: 0; right: 0; z-index: 2;
	padding: 3px;
	opacity: 0;
	.user-select(none);
}

// ブロック
.bs-ed-edititem {
	position: relative;
	margin: 0; margin-right: -66px;
	//padding: 7px; padding-right: 75px;
	padding: 0 75px 0 7px;
	border-radius: 3px;
	
	&:hover,
	&.ui-sortable-helper {
		z-index: 1;
		background: fade(#fff, 20%);
		
		.bs-ed-edititem-tool {
			opacity: 1;
		}
	}

	&.ui-sortable-helper {
		opacity: 0.8;
	}

	& + & {
		margin-top: -(@bs-ed-inserter-height + 2 * @bs-ed-inserter-margin);
	}

	.bs-ed-inserter-cnt {
		visibility: hidden;
	}

	&.hover,
	&.focus {
		//outline: dotted 3px #f88;

		.bs-ed-inserter-cnt {
			visibility: visible;
		}
	}
}

// ================================================================
// 挿入ボタン

.bs-ed-inserter-cnt {
	position: relative;
	height: @bs-ed-inserter-height + 2 * @bs-ed-inserter-margin;
	margin: 0; padding: @bs-ed-inserter-margin 0;
	overflow: hidden;
//	background: fade(#f88, 20%);
	text-align: center;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: @bs-ed-inserter-height / 2 + @bs-ed-inserter-margin; left: 0; right: 0;
		border-bottom: solid 1px #ddd;
	}
}

.bs-ed-inserter-open-btn {
	position: relative; z-index: 1;
	width: 40px; height: @bs-ed-inserter-height;
	padding: 0;
	font: 200 14px/26px Arial, sans-serif;
	text-align: center;
	color: #bbb;
	background: #fff;
	// border: solid 1px #ddd;
	border: none;
	border-radius: 6px;
	cursor: pointer;

	&:hover,
	&:focus { color: #888; border-color: #aaa; outline: none; }

	&:active { color: #888; border-color: #aaa; background: #eee; }

	.bs-ed-icon {
		display: inline-block;
		height: 26px;
		padding: 6px 0;
	}
}

.bs-ed-inserter {
	position: absolute; .pos(@bs-ed-inserter-margin, 0); z-index: 2;
	text-align: center;
}

.bs-ed-inserter-btns {
	display: inline-block;
	height: @bs-ed-inserter-height;
	color: #888; background: #fff;
	border: solid 1px #aaa; border-radius: 6px;
	overflow: hidden; // border-radius を適用

	> .bs-ed-inserter-btn:first-child { padding-left: 10px; }
	> .bs-ed-inserter-btn:last-child { padding-right: 10px; }
}

.bs-ed-inserter-btn {
	height: @bs-ed-inserter-height - 2px;
	margin: 0; padding: (@bs-ed-inserter-height - 2px - 16px)/2 (8px + @bs-ed-inserter-margin);
	line-height: 16px;
	text-align: center;
	color: #888; background: #fff;
	border: none;

	& + & {
		border-left: solid 1px #ddd;
	}

	&:hover,
	&:focus { color: #666; }

	&:active { background: #eee; }

	.bs-ed-icon {
		line-height: 16px;
	}
}

// after separator
.bs-ed-inserter-btn-as {
	display: none;
}
.bs-ed-inserter-btn-back {
	border-left: none !important;
}

.bs-ed-inserter-btn-alt {
	.bs-ed-inserter-btn {
		display: none;
	}
	.bs-ed-inserter-btn-as {
		display: inline-block;
	}
}

.bs-ed-inserter-btn-ic {
	display: inline-block; vertical-align: middle;
	font-size: 16px;
}

.bs-ed-inserter-btn-tx {
	display: inline-block; vertical-align: middle;
	margin-left: 4px;
	font-size: 75%;
}
