// ================================================================
// 書式つきテキスト

.bs-ed-preview-Richtext {
	margin: 5px 0;
}

.bs-ed-view-Richtext {
	position: relative;

	.bs-ed-root {
		min-height: 1.5em;
		font-size: 15px; line-height: 1.5;
		cursor: text;

		outline: none;
	}

	/*
	.tools {
		position: absolute; z-index: 99;
		display: none;
		padding: 4px;
		background: #ececea;
		border: solid 1px #d8d8d6; border-radius: 3px;
		box-shadow: 0 2px 5px fade(#000, 20%);

		&.on {
			display: inline-block;
		}

		// button
		.bs-ed-act {
			padding: 6px 5px;
			color: #333; background: transparent;
			border: none;
			border-radius: 3px;

			&:active {
				background: fade(#000, 20%);
			}
		}
	}
	  */
}

