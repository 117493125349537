.bs-ed-view-Anchor-wrap {
	display: inline-block;
	vertical-align: top;
	padding: 1px;
	color: #fff;
	background: #787c7e;
	border-radius: 5px;
	overflow: hidden;

	line-height: 1.5;
	font-weight: 400;

	> span,
	> input {
		padding: 2px 4px;
	}

	> span {
		padding-right: 0;
		opacity: .6;
	}

	> input {
		box-sizing: border-box;
		width: 150px;
		color: inherit;
		background: transparent;
		border: none;

		&:focus {
			background: rgba(255, 255, 255, .2);
			border: none;
			outline: none;
		}
	}
}

.bs-ed-view-Anchor {
	.bs-ed-btn-tr {
		padding: 2px 4px;
		opacity: 0;

		.bs-ed-edititem.hover &,
		.bs-ed-edititem.focus & {
			opacity: 1;
		}
	}
}
