.bs-ed-input {
	display: block;
	width: 100%;
	padding: .375em .75em;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: .25em;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
	}

	&:disabled {
		color: #666;
		background-color: #eee;
		cursor: default;
	}
}

// 軽めの表示
.bs-ed-input-lt {
	padding: .375em .25em;
	line-height: 1.42857143;
	color: #333;
	background: transparent;
	border: none;
	border-bottom: 1px solid #ddd;

	&:focus {
		background: fade(#fff, 80%);
	}

	&:focus,
	&:hover {
		border-color: #bbb;
	}
}

// 透明フォーム
.bs-ed-input-tr {
	display: block;
	width: 100%;
	background: transparent;
	border: none;
}

// placeholder color
.bs-ed-input,
.bs-ed-input-tr {
	@color: #aaa;

	&::placeholder {
		color: @color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: @color;
		opacity: 1;
	}
}

.bs-ed-form-label {
	padding: 0 0 .375em;
	font-weight: 700;
}

.bs-ed-checkbox {
	display: inline-block;
	padding: .375em 0;
}

.bs-ed-input-desc {
	margin: .375em 0 0;
	font-size: 87.5%;
	color: #777;

	&:empty {
		margin: 0;
	}
}

// Input group
// ----------------------------------------------------------------

.bs-ed-input-group {
	display: flex;

	> .bs-ed-input {
		flex: 1 1 auto;
		min-width: 0;
	}

	> * {
		border-radius: 0;
	}

	> *:first-child {
		border-radius: .25em 0 0 .25em;
	}

	> *:last-child {
		border-radius: 0 .25em .25em 0;
	}

	> * ~ * {
		border-left: none;
	}
}

// Label + controls
// ----------------------------------------------------------------

.bs-ed-form-group {
	& + & {
		margin-top: .5em;
	}
}

@media (min-width: 768px) {
	.bs-ed-form-group {
		display: flex;
	}

	.bs-ed-form-label {
		flex: 0 0 auto;
		width: 20%;
		min-height: 1px;
		margin-bottom: 0;
		padding: .375em 0 0;
		text-align: right;
	}
	
	.bs-ed-form-controls {
		flex: 0 1 auto;
		width: 80%;
		min-height: 1px;
		padding-left: 1em;
	}
}

.bs-ed-form-group-inline {
	display: flex;

	.bs-ed-form-label {
		flex: 0 0 auto;
		width: 20%;
		min-width: 5.5em;
		min-height: 1px;
		margin-bottom: 0;
		padding: .375em 0 0;
		text-align: right;
	}
	
	.bs-ed-form-controls {
		flex: 0 1 auto;
		width: 80%;
		min-height: 1px;
		padding-left: 1em;
	}
}
