// reset
// ----------------------------------------------------------------

.bs-ed-wrap {
	font-family: @font-family;
	font-weight: 400;
	line-height: 1.5;

	*,
	*::before,
	*::after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	input,
	button,
	textarea,
	select {
		font-size: inherit;
		font-family: inherit;
	}

	[tabindex="-1"]:focus,
	[tabindex="-1"]:active {
		outline: 0 !important;
	}
}

// common styles
// ----------------------------------------------------------------

.bs-ed-act {
	cursor: pointer;
}
