// ================================================================
// 表

.bs-ed-preview-Table {
	border-collapse: collapse;
	margin: 15px 0;
	//width: 100%;
	
	th, td {
		vertical-align: middle;
		padding: 7px 10px;
		border: solid 1px #ccc;
		background: #fff;
	}
	
	th {
		//width: 20%;
		font-weight: normal;
		background: @bs-ed-table-th-bg;
	}
}

.bs-ed-view-Table {
	.tool { margin: 2px 0 0; opacity: .6; }
	&:hover .tool { opacity: 1; }
}

.bs-ed-view-TableRow {
	display: table; width: 100%;
	
	.th,
	.td {
		display: table-cell; vertical-align: top;
		padding: 5px;
		border: solid 1px #ccc;
		&:hover { border-color: #aaa; }
	}

	.th {
		width: 25%; background: @bs-ed-table-th-bg;
	}
	
	.td {
		background: #fff;
	}
	
	.header > & {
		.th,
		.td {
			background: @bs-ed-table-th-bg;
		}
	}
	
	textarea {
		display: block;
		width: 100%; height: 1.5em; min-height: 1.5em;
		margin: 0; padding: 0;
		line-height: 1.5;
		border: none; resize: none; overflow: hidden;
		&:focus { outline: none; }
	}
	
	.row-tool {
		display: table-cell; vertical-align: top;
		width: 53px; padding: 3px 0 0 2px;
		opacity: 0;
	}
	&:hover .row-tool { opacity: 1; }

	// 列が1つしかない場合は「削除」「ソート」は不要
	&:first-child:last-child:hover .row-tool > * { display: none; }
}

