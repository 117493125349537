// Dialog
// ----------------------------------------------------------------

@keyframes bs-ed-dialog-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes bs-ed-dialog-out {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.bs-ed-dialog {
	font-family: @font-family;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;

	@title-font-size: 114%;
	@subtitle-font-size: 100%;

	&,
	& * {
		box-sizing: border-box;
	}
	
	& {
		overflow: auto;
		//overflow-y: scroll;
		position: fixed;
		.pos(0); z-index: 1210;
		margin: 0;
		padding: 0 1em;
		outline: 0;
		line-height: 1.4;
		-webkit-overflow-scrolling: touch;
		.user-select(none);
	}

	&.active {
		opacity: 1;
		animation: bs-ed-dialog-in linear .2s;
	}

	&.active.out {
		opacity: 0;
		animation: bs-ed-dialog-out linear .2s;
	}
	
	&-dialog {
		margin: 50px auto auto;
		max-width: 600px;
	}
	
	&.fade > &-dialog {
		transform: translate(0, -5%);
		transition: transform 0.2s ease-out;
	}
	
	&.in > &-dialog {
		transform: translate(0, 0);
	}
	
	&-content {
		position: relative;
		margin: 0; padding: 0;
		color: #333; background: #fff;
		border: 1px solid #999;
		border: 1px solid rgba(48, 48, 48, 0.2);
		border-radius: 4px;
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		background-clip: padding-box;
		outline: 0;
	}
	
	&-title,
	&-subtitle {
		margin: 10px 12px;
	}
	
	&-title {
		font-size: @title-font-size;
		font-weight: 700;
	}
	&-subtitle {
		font-size: @subtitle-font-size;
	}

	&-title + &-subtitle {
		margin-top: -4px;
	}
	
	&-actions {
		margin: 0;
		padding: .5em .75em;
		text-align: center;
		background: #eee;
		border-radius: 0 0 6px 6px;
	}
	
	&-actions > .bs-ed-btn {
		margin: 0 0.25em;
		min-width: 7em;
	}
	
	@media (min-width: 768px) {
		&-title,
		&-subtitle { margin: 18px 20px; }
		&-actions { padding: 12px 20px; }
		
		&-content {
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		}
	}
}
