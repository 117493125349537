// ================================================================
// 図

.bs-ed-preview-PictureItem {
	.caption { display: block; margin-top: 5px; text-align: center; }
	img, object { max-width: 100%; }
}

.bs-ed-view-PictureItem {
	background: #fff;
	border: solid 1px #d8d8d6;
	
	.cimg {
		position: relative;
		height: 120px;
	}
	
	.cimgrm {
		display: none;
		position: absolute; right: 3px; top: 3px; z-index: 10;
		width: 19px;
		height: 19px;
		line-height: 13px;
		font-size: 13px;
		margin: 0;
		padding: 3px 0 0;
		text-align: center;
		color: #fff; background: #bbb;
		border: none; border-radius: 20px;
		outline: none;
		
		&:hover { background: #cd1e10; }
		&:active { color: darken(#fff, 10%); background: darken(#cd1e10, 10%); }
	}
	&:hover .cimgrm { display: block; }
	&:first-child:last-child .cimgrm { display: none !important; }

	.cthumb {
		position: absolute;
		.pos(0);
		overflow: hidden;
		background: #f6f6f7 linear-gradient(to bottom, #f6f6f7, darken(#f6f6f7, 2%));
		font-weight: inherit;
		text-align: center;
		cursor: pointer;
		
		input {
			display: block;
			position: absolute; right: 0; top: 0;
			opacity: 0;
		}
		
		img { vertical-align: middle; max-width: 100%; max-height: 120px; }
	
		.placeholder {
			position: absolute; top: 50%; left: 50%;
			display: block; vertical-align: middle;
			margin: -28px -30px;
			font-size: 12px; line-height: 32px;
			color: #bbb;
			.has-img & { display: none; }
		
			&:before {
				.apply-bs-ed-icon();
				content: @bs-ed-icon-picture;
				display: block;
				top: 5px;
				font-size: 32px;
			}
		}
		&:hover {
			background: #f2f5fa linear-gradient(to bottom, #f2f5fa, darken(#f2f5fa, 3%));
		}
		&:hover .placeholder {
			color: #999;
		}
		
		// ドラッグ中のスタイル
		&.bs-ed-drag-active {
			&::after {
				content: "ここに画像をドロップ"; display: block;
				position: absolute; .pos(0); z-index: 20;
				font-size: 12px; font-weight: bold; line-height: 120px;
				color: #222; background: rgba(224, 224, 224, .8);
			}
		}
	}
	&.has-img .cthumb .placeholder { display: none; }
	
	.ctool {
		position: relative;
		padding: 5px 27px 5px 10px;
	}
	
	.ccap {
		display: block;
		margin: 0; padding: 0; resize: none; overflow: hidden; // 隠し textarea
		width: 100%; height: 18px;
		font-size: 87.5%;
		text-align: center; line-height: 18px;
		border: none; outline: none;
	}
	
	.settings {
		position: absolute;
		right: 0;
		top: 0;
		width: 28px;
		height: 28px;
		font-size: 16px;
		line-height: 16px;
		margin: 0;
		padding: 6px 0;
		text-align: center;
		color: #aaa; background: transparent;
		border: none;
		
		&:hover { color: #333; }
	}
	
	&.has-link .settings { color: @link-fg; }
}

// ================================================================
// 画像横並び

.bs-ed-preview-PictureC {
	margin: 15px -10px;
	text-align: center;

	.ccnti { display: table; width: 100%; table-layout: fixed; border-spacing: 0; }
	.bs-ed-preview-PictureItem { display: table-cell; padding: 0 10px; vertical-align: top; }
}

.bs-ed-view-PictureC {
	position: relative;
	min-height: 124px;
	
	.ccnt {
		margin: 0 -4px;
		margin-right: 40px;
	}
	
	.ccnti {
		display: flex;
		justify-content: center;
		align-items: stretch;
		width: 100%;
	}

	// .ccnti >
	.bs-ed-view-PictureItem {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		margin: 0 2px;

		> .ctool {
			flex: 1 0 auto;
		}
	}
	
	.cadd {
		position: absolute;
		right: 0; top: 0; bottom: 0;
		width: 38px; padding: 0;
	}
	.caddb {
		width: 100%; height: 100%;
		font-size: 13px;
		color: #777; border-radius: 0;
		&:hover { color: #333; }
	}
	&.full .caddb {
		color: #ccc !important; background: #f4f4f4 !important;
		border-color: #e4e4e4 !important;
		box-shadow: none !important;
		outline: none !important;
		cursor: default !important;
	}
}

