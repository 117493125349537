// mixins.less
// ----------------------------------------------------------------

// Layouts
// ----------------------------------------------------------------

.clearfix() {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content: "";
		// Fixes Opera/contenteditable bug:
		// http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}

.pos(@top, @right: @top, @bottom: @top, @left: @right) {
	top: @top;
	right: @right;
	bottom: @bottom;
	left: @left;
}

// Text
// ----------------------------------------------------------------

.text-ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Misc
// ----------------------------------------------------------------

.user-select(@val) {
	-webkit-user-select: @val;
	-moz-user-select: @val;
	user-select: @val;
}
