// .bs-ed-toolbar {
// 	margin: 0; padding: 6px 12px;
// 	font-size: 0;
// 	background: #ececea;
// 	border-top: solid 1px #d8d8d6;
// 	border-bottom: solid 1px #d8d8d6;
// 	border-radius: 0 0 4px 4px;
// 	
// 	> .btn { font-size: 12px; line-height: 18px; padding: 5px 10px; border-radius: 3px; }
// 	> .btn + .btn { margin-left: 2px; }
// }

.bs-ed-toolbutton {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	margin: 3px;
	padding: 3px 0 0;
	border: none;
	width: 19px; height: 19px;
	font-size: 13px;
	line-height: 13px;
	text-align: center;
	border-radius: 19px;
	color: #fff; background: #bbb;
	cursor: pointer;

	&:focus { outline: none; }

	&:hover { background: @bs-ed-ui-fg; }
	&:active { color: darken(#fff, 10%); background: darken(@bs-ed-ui-fg, 10%); }

	&.bs-ed-danger:hover { background: #cd1e10; }
	&.bs-ed-danger:active { background: darken(#cd1e10, 10%); }
}
