// ================================================================
// YouTube

.bs-ed-preview-YouTube:extend(.bs-ed-preview-PictureB all) {
	margin: 15px 0;
	text-align: center;
}

.bs-ed-view-YouTube {
	// valid URL
	&.llv {
		.lli .bs-ed-icon {
			color: #f22;
			opacity: 1;
		}
	}

	.lli {
		display: flex;
		align-items: center;
		width: 100%;
		border: solid 1px #ddd;
		background: #fff;

		.bs-ed-icon {
			padding: 3px;
			padding-right: 0;
			font-size: 18px;
			opacity: .8;
		}

		.bs-ed-input-tr {
			flex: 1;
			padding: 5px 8px;
		}
	}

	.llp {
		position: relative;
		text-align: center;
		height: 200px;
		background: #444 none no-repeat 50% 50% / contain;
	}
	.llph {
		display: none;
	}

	.llh {
		margin-top: 3px;
		font-weight: 700;
		color: @error-fg;
	}
}
